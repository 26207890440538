<template>
  <div class="news-list-box" v-loading="loading">
    <div style="margin: 10px 0;">
      <div
        v-for="item in list"
        :key="item.ID"
        class="list-box"
        @click="handleclick(item)"
      >
        <!-- <el-image
        v-if="list[0]['Img']"
          style="width:50px; height:50px"
          :src="url"
          fit="fit"></el-image> -->
        <div
          style="margin-left: 10px;display: flex; justify-content: space-between;width: 100%;"
        >
          <span>{{ item.Title }}</span>
          <span class="mobile-time" style="color: #666;">{{
            item.CreateDate
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import store from '@/store'
import { getInfoGet, getInfoPost } from "@/api/public";
export default {
  data() {
    return {
      TypeID: "",
      loading: false,
      list: [],
      title: ""
    };
  },
  created() {
    this.$store.commit("SET_ADDTITLES", this.$route.query.titles);
    this.title = this.$route.query.titles;
    this.TypeID = this.$route.query.TypeID;
    if (this.TypeID === 10000) {
      return;
    }
    console.log("2222", this.$route.query);
    this.getList();
  },
  methods: {
    handleclick(row) {
      this.$router.push({
        path: "/newsDetails",
        query: {
          titles: this.title,
          ID: row.ID,
          path: this.$route.fullPath
        }
      });
    },
    async getList() {
      this.loading = true;
      const r = await getInfoPost(
        { TypeID: this.TypeID },
        "/api/DB/SelectPublic"
      )
        .catch(e => {
          console.log("e", e);
        })
        .finally(() => {
          this.loading = false;
        });
      if (!r || !r.Data || !r.Data.length) {
        this.list = [];
        return;
      }
      this.list = r.Data;
    }
  }
};
</script>

<style lang="scss" scoped>
.list-box {
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

@media screen and (min-width: 320px) and (max-width: 970px) {
  .mobile-time {
    display: none;
  }
}
</style>
